import * as Api from '@/api/course/course'
import ChatList from '@/views/Live/modules/chatroom/chatList/chatList.vue'
import TCPlayer from '@/components/TCPlayer/TCPlayer.vue'
import { mapActions, mapState } from 'vuex'
import $ from 'jquery'
import EXT_IM from '@/views/Live/ext/im'


export default {
  name: 'courseReplay',
  data () {
    return {
      courseId: '',
      liveMode: '',
      videoSrc: '',
      isReplay: true,
      playerOptions: {},
      renderTime: '',
      msgShow: [],
      msgLave: [],
      showMoreMsgButton: false,
      videoStartBaseTime: '',
      timerArray: [],
      offsetArr: [],
      offsetNum: 0,
      fid: ''
    }
  },
  mounted () {

    this.liveMode = this.$route.path.includes('private') ? this.constant.LIVE_MODE.PRIVATE : this.$route.path.includes('training') ? this.constant.LIVE_MODE.TRAINING :  this.constant.LIVE_MODE.CLASS
    this.courseId = this.$route.params.id
    this.getReplayDetail()
  },
  computed: {
    ...mapState({
      userId: state => state.tic.userId,
      msgs: state => state.tic.msgs
    })
  },
  methods: {
    getReplayDetail () {
      Api.replayCourseDetail('', this.courseId, this.liveMode, res => {
        this.videoStartBaseTime = res.video_started_at[0].time
        this.fid = res.video_id
        let self = this
        this.timerArray = res.video_started_at
        this.calcOffset()
        $.ajax({
          url: `${res.im_record_url}`,
          type: 'get',
          dataType: 'jsonp',
          jsonp: 'jsonp_callback',
          jsonpCallback: 'ImMessage',
          success: function (res) {
            self.msgLave = res.reverse()
          }
        })
      }, this.liveMode === 3 ? 'v2' : '')
    },
    renderMsg (currentTime) {
      this.msgLave.forEach((ele) => {
        if (ele.timestamp <= currentTime) {
          if (!ele.isShow) {
            this.handleMsgShowInBox(ele)
          }
          ele.isShow = true
        }
      })
    },
    handleMsgShowInBox (msg) {
      let accountObj = msg.from_account
      let account = ''
      if (accountObj.identity !== 'teacher') {
        account = 'personal'
      } else {
        account = accountObj.nickname || accountObj.account
      }
      let msgContent = msg.body

      if (msg.body.msg_type === 'TIMTextElem') {
        EXT_IM.handleGroupTextMsg(msgContent.msg_content.text).then((text) => {
          this.addMsg({
            send: account,
            content: text,
            type: 'chat',
            avatar: accountObj.avatar,
            identity: accountObj.identity
          })
          this.$nextTick(() => {
            let msgbox = document.querySelector('#msg_box')
            msgbox.scrollTop = msgbox.scrollHeight
          })
        })
      } else {
        let img = `<img class="chat_img" draggable="false" alt=""  bigimgsrc="${msgContent.msg_content.images[0].url}"  style="max-width: 150px" src="${msgContent.msg_content.images[0].url}">`

        this.addMsg({
          send: account,
          content: img,
          type: 'chat',
          avatar: accountObj.avatar,
          identity: accountObj.identity
        })
        this.$nextTick(() => {
          let msgbox = document.querySelector('#msg_box')
          msgbox.scrollTop = msgbox.scrollHeight
        })
      }
    },

    calcOffset () {
      let arr = []
      let offset = 0
      this.timerArray.forEach(ele => {
        offset = offset + parseInt(ele.duration)
        arr.push(offset)
      })
      this.offsetArr = arr
    },

    onPlayerTimeupdate (player) {
      let baseTimeTamp = parseInt(this.videoStartBaseTime)
      let currentTime = Math.floor(player.currentTime())
      let offsetArr = this.offsetArr
      for (let i = 0; i < offsetArr.length; ++i) {
        if (currentTime >= offsetArr[i] && currentTime < offsetArr[i + 1]) {
          this.videoStartBaseTime = parseInt(this.timerArray[i + 1].time)
          this.offsetNum = offsetArr[i]
        }
      }
      let calcTime = baseTimeTamp + currentTime - this.offsetNum
      this.renderMsg(calcTime)
    },

    onPlayerEnded () {
      this.showMoreMsgButton = true
    },
    showLaveMsg () {
      this.msgLave.forEach((ele) => {
        if (!ele.isShow) {
          this.handleMsgShowInBox(ele)
          ele.isShow = true
        }
      })
      this.showMoreMsgButton = false
    },

    ...mapActions([
      'addMsg',
      'setLiveMode'
    ])
  },
  watch: {},
  components: {
    ChatList,
    TCPlayer
  }
}
